export enum Locales {
    DE = 'de',
    EN = 'en',
    PL = 'pl',
    RU = 'ru',
}

export interface SessionToken {
    accessToken: string;
    payload: {
        sessionId: string;
        locale: Locales;
    };
}
