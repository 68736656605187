import axios from 'axios';
import { FMFile, FMFileStatus } from '../components/FileManager/interfaces';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const uploadFile = async (file: FMFile, uuid?: string) => {
    const data = new FormData();
    const url = URL.createObjectURL(file.meta);
    data.append('file', file.meta);
    data.append('type', '38');
    if (uuid) data.append('uuid', uuid);
    try {
        const res = await axios.post(
            `${SERVER_URL}/v1/common/file/upload`,
            data
        );
        if (res.data.status === 0) {
            return {
                ...file,
                id: res.data.response.id,
                status: FMFileStatus.Success,
                url,
            };
        } else {
            throw new Error('Failed to upload');
        }
    } catch (e) {
        console.error(e);
        return {
            ...file,
            status: FMFileStatus.Failed,
            url,
        };
    }
};

export const deleteFile = (id: number) =>
    axios.post(`${SERVER_URL}/v1/common/file/delete`, { id });
