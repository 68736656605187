import { useEffect, useMemo, useState } from 'react';
import { Image, Divider } from 'antd-mobile';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styles from './App.module.css';
import { FileManager } from '../FileManager/FileManager';
import { FMFile, FMFileStatus } from '../FileManager/interfaces';
import '../../utils/i18next';
import { Error as ErrorComponent } from '../Error/Error';
import i18next from '../../utils/i18next';
import { parseToken } from '../../utils/token/token';
import { SessionToken } from '../../utils/token/interfaces';

export const App = () => {
    const { t } = useTranslation();

    const [token, setToken] = useState<SessionToken>();
    const [files, setFiles] = useState<FMFile[]>([]);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        parseToken(
            (sessionToken) => {
                i18next.changeLanguage(sessionToken.payload.locale);
                setToken(sessionToken);
                axios.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${sessionToken.accessToken}`;
            },
            () => setIsError(true)
        );
    }, []);

    const totalFilesAmount = files.length;
    const successFilesAmount = useMemo(() => {
        return files.reduce(
            (acc, item) =>
                item.status === FMFileStatus.Success ? acc + 1 : acc,
            0
        );
    }, [files]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.container}>
                    <Image className={styles.logo} alt="logo" src="logo.svg" />
                </div>
            </div>
            {!isError && (
                <>
                    <Divider className={styles.headerDivider} />
                    <div className={styles.container}>
                        <div className={styles.title}>{t('addingFiles')}</div>
                    </div>
                    <Divider />
                    <div className={styles.container}>
                        <div className={styles.subtitle}>{t('files')}</div>
                        {!totalFilesAmount && (
                            <div className={styles.info}>{t('noFiles')}</div>
                        )}
                        {!!totalFilesAmount && (
                            <div className={styles.info}>
                                {t('successFilesAmount', {
                                    successAmount: successFilesAmount,
                                    totalAmount: totalFilesAmount,
                                })}
                            </div>
                        )}

                        <FileManager
                            files={files}
                            setFiles={setFiles}
                            uuid={token?.payload?.sessionId}
                        />
                    </div>
                    <Divider />
                </>
            )}
            {isError && <ErrorComponent className={styles.container} />}
        </div>
    );
};
